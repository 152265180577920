<template>
    <div id="canvas-web" class="home notranslate">
        <div class="head-browser">
            <div class="dots">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
        <div id="header" class="section" v-on:click="select('header')"
            :class="{ selected: sectionSelected =='header' }">
            <div :class="{ selectedDiv: sectionSelected == 'header' }" v-if="sectionSelected == 'header'"></div>
            <header class="main-header">
                <nav class="navbar navbar-expand-lg nav-bg-active" id="mainNav">
                    <div class="container">
                        <a class="navbar-brand">
                            <img :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_logo+ '?v=' + this.timeStamp"
                                alt="Logotipo de la empresa" class="logo">
                        </a>
                        <div class="d-flex flex-row align-items-center order-2 order-lg-3">
                            <a class="nav-link btn-ws ms-3" v-if="cliente.Tiene_chatbot > 0">
                                <span class="fa-brands fa-whatsapp"></span>
                                <span class="visually-hidden">Icono whatsapp</span>
                            </a>
                            <a class="nav-link btn-call ms-3">
                                <span class="fa fa-phone" aria-hidden="true"></span>
                                <span class="span-tlf" id="telefono5">{{ cliente.Telefono }}</span>
                            </a>
                            <div class="dropdown wrap-langs">
                                <a href="javascript:void(0);" class="d-flex align-items-center gap-2 nav-link ptrans" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span id="text-curlang" class="text-lang" translate="no">es</span>
                                    <i class="fa fa-globe" aria-hidden="true"></i>
                                </a>
                            </div>
                        </div>
                        <div class="collapse navbar-collapse align-items-center justify-content-end order-3 order-lg-2">
                            <ul class="navbar-nav">
                                <li class="nav-item">
                                    <a class="nav-link active">Inicio</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">Servicios</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">Sobre nosotros</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link">Contacto</a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
        <div id="principal" class="section" v-on:click="select('principal')"
            :class="{ selected: sectionSelected == 'principal' }" v-if="pageSelected == 'Inicio'">
            <div :class="{ selectedDiv: sectionSelected == 'principal' }" v-if="sectionSelected == 'principal'"></div>
            <section class="hero main-banner section-bg-light">
                <div class="container">
                    <div class="row d-flex align-items-center">
                        <div class="col-lg-7 col-md-6">
                            <div class="hero-content">
                                <h1 class="title" v-html="cliente.Datos_json.Titulo_principal"></h1>
                                <div class="description pe-none" v-html="cliente.Datos_json.Descripcion_principal"></div>
                                <a class="btn btn-primary rounded-pill btn-canva mt-4">Sobre Nosotros</a>
                            </div>
                        </div>
                        <div class="col-lg-5 col-md-6">
                            <div class="wrap-card">
                                <div class="slider card">
                                    <div class="d-flex flex-column justify-content-between">
                                        <div class="image-item">
                                            <img
                                                :src="(
                                                    cliente.Datos_json?.Servicios[0]?.Img ?
                                                    cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Servicios[0].Img + '?v=' + this.timeStamp :
                                                    ''
                                                )"
                                                @error="setDefaultServiceImage">
                                        </div>
                                        <div class="footer-item">
                                            <button type="button"
                                                class="slick-prev icon-arrow-left slick-arrow"></button>
                                            <ul class="slick-dots" style="" role="tablist">
                                                <li class="slick-active" aria-hidden="false" role="presentation"
                                                    aria-selected="true" aria-controls="navigation00"
                                                    id="slick-slide00"><button type="button" class="rounded"
                                                        data-role="none" role="button" tabindex="0"></button></li>
                                                <li aria-hidden="true" role="presentation" aria-selected="false"
                                                    aria-controls="navigation01" id="slick-slide01"><button
                                                        type="button" class="rounded" data-role="none" role="button"
                                                        tabindex="0"></button></li>
                                                <li aria-hidden="true" role="presentation" aria-selected="false"
                                                    aria-controls="navigation02" id="slick-slide02"><button
                                                        type="button" class="rounded" data-role="none" role="button"
                                                        tabindex="0"></button></li>
                                            </ul>
                                            <button type="button"
                                                class="slick-next icon-arrow-right slick-arrow"></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
        <div class="main-wrap">
            <div id="servicios" class="section" v-on:click="select('servicios')"
                :class="{ selected: sectionSelected == 'servicios' }" v-if="pageSelected == 'Inicio'">
                <div :class="{ selectedDiv: sectionSelected == 'servicios' }" v-if="sectionSelected == 'servicios'">
                </div>
                <section class="lp-sections services">
                    <div class="container">
                        <div class="text-center mb-5">
                            <h2 class="section-title">Servicios Destacados</h2>
                        </div>
                        <div class="row">
                            <div class="wrap-items-services col-lg-4 col-md-6"
                                v-for="(service, index) in cliente.Datos_json.Servicios.slice(0, 3)" :key="index">
                                <div class="card d-flex flex-column justify-content-between">
                                    <div class="card-header wrap-icon">
                                        <img class="img-fluid"
                                            :src="cliente.urlImg + '/' + this.folder + '/' + service.Img + '?v=' + this.timeStamp" 
                                            @error="setDefaultServiceImage">
                                    </div>
                                    <div class="card-body">
                                        <h3 class="title" v-html="service.Titulo"></h3>
                                        <div class="description pe-none" v-html="service.Descripcion"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center my-4">
                            <a class="btn btn-primary rounded-pill btn-canva">Todos los servicios</a>
                        </div>
                    </div>
                </section>
            </div>
            <div id="sobreNosotros" class="section" v-on:click="select('sobreNosotros')"
                :class="{ selected: sectionSelected == 'sobreNosotros' }"
                v-if="pageSelected == 'Inicio'">
                <div :class="{ selectedDiv: sectionSelected == 'sobreNosotros' }"
                    v-if="sectionSelected == 'sobreNosotros'"></div>
                <section class="lp-sections section-bg-light about">
                    <div class="container">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 order-5">
                                <div class="wrap-about-text">
                                    <h2 class="section-title">Conoce más sobre <span v-html="cliente.Datos_json.Titulo_principal"></span></h2>
                                    <div class="description pe-none" v-html="cliente.Datos_json.Descripcion_sobre_nosotros_corta"></div>
                                </div>
                            </div>
                            <div class="col-md-6 order-1">
                                <div class="wrap-card">
                                    <div class="card">
                                        <div class="wrap-image">
                                            <img class="img-fluid"
                                                :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_sobre_nosotros + '?v=' + this.timeStamp" 
                                                @error="setDefaultServiceImage">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <!--
            <div id="criticas" class="section" v-if="pageSelected == 'Inicio' && cliente.reviews.length > 0"
                v-on:click="select('criticas')" :class="{ selected: sectionSelected == 'criticas' }">
                <div :class="{ selectedDiv: sectionSelected == 'criticas' }" v-if="sectionSelected == 'criticas'"></div>
                <section class="testimonials" id="seccionCriticas">
                    <h2 class="title">¿Qué dice la gente sobre nosotros?</h2>
                    <div class="slider">
                        <div class="slider__content">
                            <button type="button" data-role="none" class="slick-prev icon-arrow-left slick-arrow"
                                aria-label="Previous" role="button" style=""></button>
                            <div class="box">
                                <div class="user-review">
                                    <img src="" alt="imagen cliente">
                                    <p class="name">{{ cliente.reviews[0].name_reviewer }}</p>
                                </div>
                                <p>{{ cliente.reviews[0].COMMENT }}</p>
                            </div>
                            <button type="button" data-role="none" class="slick-next icon-arrow-right slick-arrow"
                                aria-label="Next" role="button" style=""></button>
                        </div>
                        <div class="slick-dots">
                            <button type="button" class="rounded slick-active" data-role="none" role="button"
                                tabindex="0"></button>
                            <button type="button" class="rounded" data-role="none" role="button" tabindex="0"></button>
                            <button type="button" class="rounded" data-role="none" role="button" tabindex="0"></button>
                            <button type="button" class="rounded" data-role="none" role="button" tabindex="0"></button>
                            <button type="button" class="rounded" data-role="none" role="button" tabindex="0"></button>
                        </div>
                    </div>
                </section>
            </div>
            -->
            <div id="find-us" class="section" v-if="pageSelected == 'Inicio'" v-on:click="select('find-us')"
                :class="{ selected: sectionSelected == 'find-us' }">
                <div :class="{ selectedDiv: sectionSelected == 'find-us' }" v-if="sectionSelected == 'find-us'"></div>
                <section class="lp-sections find-us pb-0">
                    <div class="container px-5">
                        <div class="text-center mb-5">
                            <h2 class="section-title">¿Cómo encontrarnos?</h2>
                        </div>
                        <div class="row" style="justify-content: space-around;">
                            <div class="col-md-4">
                                <div class="address">
                                    <div class="icon" alt="logo direccion"><span class="fa fa-location-dot"></span>
                                    </div>
                                    <p v-if="cliente.Datos_json.cp" class="description pe-none" v-html="((cliente.Datos_json.Calle ? cliente.Datos_json.Calle + '<br>' : '') + cliente.Datos_json.cp + ' ' + cliente.Datos_json.Localidad + '<br>' + cliente.Datos_json.Provincia)"></p>                                    <p v-if="!cliente.Datos_json.cp && !cliente.Calle" class="description pe-none">{{ cliente.Direccion }}</p>
                                    <p v-if="!cliente.Datos_json.cp && cliente.Calle" class="description pe-none" v-html="(cliente.Calle + ' ' + cliente.Numero_calle + '<br>'+ cliente.Codigo_postal + ' ' + cliente.Ciudad + '<br>' + cliente.Provincia)"></p>
                                </div>
                            </div>
                            <div v-if="cliente.Horario" class="col-md-4">
                                <div class="work-hours">
                                    <div class="icon" alt="logo horario"><span class="fa fa-clock"></span></div>
                                    <p class="description pe-none" v-html="cliente.Horario"></p>
                                </div>
                            </div>
                            <div class="col-md-4">
                                <div class="call-us">
                                    <div class="icon" alt="logo telefono"><span class="fa fa-phone"></span></div>
                                    <p class="description pe-none">{{ cliente.Telefono }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="wrap-map">
                        <iframe :src="getGoogleMapsUrl(cliente)" style="border: 0;"
                            allowfullscreen=""></iframe>
                    </div>
                </section>
            </div>
            <div id="contacto" class="section" v-if="pageSelected == 'Inicio' || pageSelected == 'Contacto'"
                v-on:click="select('contacto')" :class="{ selected: sectionSelected == 'contacto' }">
                <div :class="{ selectedDiv: sectionSelected == 'contacto' }" v-if="sectionSelected == 'contacto'"></div>
                <section id="contacto" class="lp-sections section-bg-light contact">
                    <div class="container">
                        <div class="text-center mb-5 pb-5">
                            <h2 class="section-title mb-3">Contacta con nosotros</h2>
                            <h3 class="fs-4 mb-4">¡Cuentanos tus ideas y empecemos!</h3>
                        </div>
                        <div class="row align-items-center justify-content-between gap-5">
                            <div class="col-md-5">
                                <form action="#" class="form" id="formulario" runat="server">
                                    <div class="mb-4">
                                        <label for="txtNombre" class="form-label">Nombre<span
                                                class="text-muted small ms-1">(Obligatorio)</span></label>
                                        <input type="text" id="txtNombre" class="form-control"
                                            placeholder="Escribe aquí tu nombre">
                                    </div>
                                    <div class="mb-4">
                                        <label for="txtTlf" class="form-label">Teléfono<span
                                                class="text-muted small ms-1">(Obligatorio)</span></label>
                                        <input type="text" id="txtTlf" class="form-control"
                                            placeholder="Escribe aquí tu teléfono">
                                    </div>
                                    <div class="mb-4">
                                        <label for="txtEmail" class="form-label">Email<span
                                                class="text-muted small ms-1">(Obligatorio)</span></label>
                                        <input type="text" id="txtEmail" class="form-control"
                                            placeholder="Escribe aquí tu email">
                                    </div>
                                    <div class="mb-4">
                                        <label for="ddlServForm" class="form-label">Servicios disponibles<span
                                                class="text-muted small ms-1">(Obligatorio)</span></label>
                                        <select class="form-select" id="ddlServForm">
                                            <option v-for="service in cliente.Datos_json.Servicios"
                                                :key="service.Titulo">
                                                {{ service.Titulo }}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="mb-4 textarea">
                                        <label for="txtConsulta" class="form-label">Mensaje<span
                                                class="text-muted small ms-1">(Obligatorio)</span></label>
                                        <textarea id="txtConsulta" class="form-control"
                                            placeholder="Escribe aquí tu mensaje" Rows="5"></textarea>
                                    </div>
                                    <div class="form-check terms">
                                        <input type="checkbox" class="form-check-input" name="politica[]" id="politica">
                                        <label for="politica" class="form-check-label">
                                            Acepto y he leído la <a class="lp-link">política de privacidad</a> y el <a
                                                class="lp-link">tratamiento de datos</a>
                                        </label>
                                    </div>
                                    <div class="mt-5">
                                        <button class="btn btn-primary rounded-pill btn-canva">Enviar</button>
                                    </div>
                                </form>
                            </div>
                            <div class="col-md-5">
                                <div class="wrap-card">
                                    <div class="card">
                                        <div class="wrap-image">
                                            <img :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_contacto + '?v=' + this.timeStamp"
                                                class="img-fluid" >
                                        </div>
                                    </div>
                                </div>
                                <h3 class="title text-center mt-5">Otras formas de ponerte en contacto con nosotros</h3>
                                <div class="icons gap-5">
                                    <div class="icon-with-text">
                                        <a class="stretched-link"><span class="fa fa-phone"></span></a>
                                        <span class="icon-text">Llamada</span>
                                    </div>
                                    <div class="icon-with-text" v-if="cliente.Tiene_chatbot > 0">
                                        <a class="stretched-link"><span class="fa-brands fa-whatsapp"></span></a>
                                        <span class="icon-text">Whatsapp</span>
                                    </div>
                                    <div class="icon-with-text">
                                        <a class="stretched-link"><span class="fa fa-envelope"></span></a>
                                        <span class="icon-text">Email</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>
        <div id="pantallaServicios" class="services" v-if="pageSelected == 'Servicios'">
            <div class="main-wrap">
                <div id="listServicios" class="section" v-on:click="select('listServicios')"
                    :class="{ selected: sectionSelected == 'listServicios' }" v-if="pageSelected == 'Servicios'">
                    <div :class="{ selectedDiv: sectionSelected == 'listServicios' }"
                        v-if="sectionSelected == 'listServicios'"></div>
                    <section class="lp-sections section-bg-light services">
                        <div class="container">
                            <div class="text-center mb-5">
                                <h2 class="section-title">Nuestros Servicios</h2>
                            </div>
                            <div class="row">
                                <div class="wrap-items-services items-hrz col-md-12"
                                    v-for="(service, index) in cliente.Datos_json.Servicios" :key="index">
                                    <div class="card d-flex align-items-start justify-content-between">
                                        <div class="card-header wrap-icon">
                                            <img class="img-fluid"
                                                :src="cliente.urlImg + '/' + this.folder + '/' + service.Img + '?v=' + this.timeStamp"
                                                @error="setDefaultServiceImage">
                                        </div>
                                        <div class="card-body">
                                            <h3 class="title" v-html="service.Titulo"></h3>
                                            <p class="description pe-none" v-html="service.Descripcion"></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
        <div id="PantallaSobreNosotros" class="section" v-on:click="select('PantallaSobreNosotros')"
                :class="{ selected: sectionSelected == 'sobreNosotros' }"
                v-if="pageSelected == 'Sobre Nosotros'">
                <div :class="{ selectedDiv: sectionSelected == 'sobreNosotros' }"
                    v-if="sectionSelected == 'sobreNosotros'"></div>
                <section class="lp-sections section-bg-light about">
                    <div class="container">
                        <div class="row d-flex align-items-center">
                            <div class="col-md-6 order-5">
                                <div class="wrap-about-text">
                                    <h2 class="section-title">Conoce más sobre <span v-html="cliente.Datos_json.Titulo_principal"></span></h2>
                                    <div class="description pe-none" v-html="cliente.Datos_json.Descripcion_sobre_nosotros"></div>
                                </div>
                            </div>
                            <div class="col-md-6 order-1">
                                <div class="wrap-card">
                                    <div class="card">
                                        <div class="wrap-image">
                                            <img class="img-fluid"
                                                :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_sobre_nosotros + '?v=' + this.timeStamp"
                                                @error="setDefaultServiceImage">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        <div id="footer" class="section" v-on:click="select('footer')"
            :class="{ selected: sectionSelected == 'footer' }">
            <div :class="{ selectedDiv: sectionSelected == 'footer' }" v-if="sectionSelected == 'footer'"></div>
            <footer class="main-footer">
                <div class="container">
                    <div class="main-footer-top">
                        <div class="row">
                            <div class="col-md-3">
                                <div class="wrap-logo">
                                    <div class="logo-footer">
                                        <img :src="cliente.urlImg + '/' + this.folder + '/' + cliente.Datos_json.Img_logo + '?v=' + this.timeStamp"
                                            class="img-fluid">
                                    </div>
                                    <div class="social-footer">
                                        <a class="fa-brands fa-instagram" v-if="cliente.Datos_json.Perfil_instagram"></a>
                                        <a class="fa-brands fa-facebook-f" v-if="cliente.Datos_json.Perfil_facebook"></a>
                                        <a class="fa-brands fa-linkedin-in" v-if="cliente.Datos_json.Perfil_linkedin"></a>
                                        <a class="fa-brands fa-twitter" v-if="cliente.Datos_json.Perfil_twitter"></a>
                                    </div>
                                    <div id="divRegSanitario" runat="server"></div>
                                </div>
                            </div>
                            <div class="col-md-9">
                                <div class="row">
                                    <div class="col-md-3 is_desktop">
                                        <p class="f-title pe-none">Acerca</p>
                                        <div class="links gap-3">
                                            <a>Home</a>
                                            <a>Sobre nosotros</a>
                                            <a>Contacto</a>
                                        </div>
                                    </div>
                                    <div class="col-md-3 is_desktop">
                                        <p class="f-title pe-none">Servicios</p>
                                        <div class="links gap-3">
                                            <a v-for="service in cliente.Datos_json.Servicios" :key="service.Titulo"
                                                v-html="service.Titulo"></a>
                                        </div>
                                    </div>
                                    <div class="col-md-3 pe-none">
                                        <p class="f-title">Oficinas</p>
                                        <p v-if="!cliente.Calle" class="description pe-none">{{ cliente.Direccion }}</p>
                                        <p v-if="cliente.Calle" class="description pe-none" v-html="(cliente.Calle + ' ' + cliente.Numero_calle + '<br>'+ cliente.Codigo_postal + ' ' + cliente.Ciudad + '<br>' + cliente.Provincia)"></p>
                                    </div>
                                    <div class="col-md-3 pe-none">
                                        <p class="f-title">Contacto</p>
                                        <p class="info">{{ cliente.Telefono }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="main-footer-bot">
                        <div class="row">
                            <div class="col-md-5">
                                <p class="copyright pe-none">2022 © Todos los derechos reservados {{ cliente.Nombre }}
                                </p>
                            </div>
                            <div class="col-md-7">
                                <div class="nav-legal d-flex">
                                    <a>Términos de uso</a>
                                    <div class="vr"></div>
                                    <a>Política de privacidad</a>
                                    <div class="vr"></div>
                                    <a>Política de cookies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
        <div id="kit-digital" class="section">
            <section class="programa-kit-digital">
                <div class="wrap-kit-digital section-bg-light border-top">
                    <div class="container">
                        <div class="d-flex align-items-end justify-content-between py-1 gap-3">
                            <div class="logo-kit-digital">
                                <img src="../assets/img/logos-footer-kd.png" class="img-fluid" alt="">
                            </div>
                            <div class="land-footer-brands">
                                <div class="logo-wcag">
                                    <img src="../assets/img/logo-wcag-2.1-aw.svg" class="img-fluid" alt="">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PantallaGeneral',
    props: {
        pageSelected: String,
        cliente: Object,
        folder: String,
        timeStamp: Object
    },
    mounted() {
    },
    data() {
        return {
            sectionSelected: 'none',
        }
    },
    methods: {
        select: function (id) {
            this.sectionSelected = id;
            this.$emit('clicked', id);
            let emain = document.querySelector("#main");
            if (!emain.classList.contains("sow-sidebar")) {
                emain.classList.add('sow-sidebar');
            }
        },
        cleanSelect() {
            this.sectionSelected = '';
        },
        getGoogleMapsUrl: function (cliente) {
            if(cliente.Datos_json.MapaGoogle)
                return "https://www.google.com/maps/embed/v1/place?key=AIzaSyDoxaiYPSFnyPIidF9XhlZ5SS4sqN7Q3fE&q=" + cliente.Datos_json.MapaGoogle
            else{
                if(cliente.Datos_json.cp){
                    return "https://www.google.com/maps/embed/v1/place?key=AIzaSyDoxaiYPSFnyPIidF9XhlZ5SS4sqN7Q3fE&q=" 
                    + cliente.Datos_json.Calle + " "
                    + cliente.Datos_json.cp + " "
                    + cliente.Datos_json.Localidad + " "
                    + cliente.Datos_json.Provincia + " ";
                }else
                    return "https://www.google.com/maps/embed/v1/place?key=AIzaSyDoxaiYPSFnyPIidF9XhlZ5SS4sqN7Q3fE&q=" + cliente.Direccion;
            }
        },
        setDefaultServiceImage(event) {
            console.log(`Error al cargar la imagen: ${event.target.src}`);
            if (event.target.src != process.env.VUE_APP_IMG_LINK_SERVICE_DEFAULT) {
                event.target.src = process.env.VUE_APP_IMG_LINK_SERVICE_DEFAULT;
            }
        },
    },
}
</script>

<!-- <style scoped src="@/assets/css/style.css"></style> -->
<style scoped src="@/assets/css/lp-styles.css?v=1.0.2">

</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.home {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    margin-bottom: 30px;
    border: none;
    border-radius: 6px 6px 8px 8px;
    -webkit-box-shadow: 0px 0px 35px rgb(95 94 231 / 25%);
    box-shadow: 0px 0px 35px rgb(95 94 231 / 25%);
    overflow: hidden;
}

.home img {
    display: flex;
    margin: 0 auto;
}

.head-browser {
    position: relative;
    width: 100%;
    height: 35px;
    background-color: #d1d2eb;
    display: flex;
    align-items: center;
    z-index: 55;
}

.head-browser .dots {
    display: flex;
    align-items: center;
    gap: 0.25rem;
    padding: 0 15px;
}

.head-browser .dots span {
    width: 10px;
    height: 10px;
    border-radius: 20px;
    background-color: #a1a3c9;
}

.section {
    position: relative;
}

.selected .selectedDiv {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    background-color: rgba(var(--main-primary-color-rgb), .35);
    border: 3px solid var(--main-primary-color);
    z-index: 99;
}
</style>
