import { createApp } from 'vue'
import App from './App.vue'
import NotFound from './NotFound.vue'
import MyHome from './Home.vue'
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";
import { createRouter, createWebHistory } from "vue-router"
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import VueTrumbowyg from 'vue-trumbowyg';
import 'trumbowyg/dist/ui/trumbowyg.css';

const app = createApp(App)
const routes = [
  { 
    path: '/NotFound',
    name : 'NotFound',
    component : NotFound     
  },
  { 
    path: '/',
    name : 'Home',
    component : MyHome     
  }
];
const router = createRouter({
  history: createWebHistory(),
  routes,
});
app.use(router);
app.use(VueTrumbowyg);
app.mount('#app')

app.use(Toast,{
    position: POSITION.TOP_CENTER,
    timeout: 2000
})