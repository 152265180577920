<template>
  <div class="modal-cropper" :class="{ modalActive: showCropper }">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-body">
          <section class="cropper-area">
            <div class="img-cropper">
              <vue-cropper ref="cropper" :src="loadedImg" :viewMode="0" drag-mode="move" :autoCropArea="0.80" :restore="false"
                :modal="false" :guides="false" :highlight="false" :cropBoxMovable="false" :cropBoxResizable="false" :minContainerWidth="500" :minContainerHeight=this.minContainerHeight :minCanvasWidth=this.minCanvasWidth :minCanvasHeight="100" />
            </div>
            <div class="actions d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center gap-4">
                <a href="#" class="btn-flat" @click.prevent="zoom(0.1)" role="button"><i
                    class="fa-solid fa-plus-circle"></i>Acercar</a>
                <a href="#" class="btn-flat" @click.prevent="zoom(-0.1)" role="button"><i
                    class="fa-solid fa-minus-circle"></i>Alejar</a>
              </div>
              <div class="dropdown">
                <a href="#" class="btn-flat dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false"
                  role="button">
                  Más acciones
                </a>
                <ul class="dropdown-menu dropdown-menu-end">
                  <li><a href="#" class="dropdown-item" @click.prevent="rotate(90)" role="button">Rotar +90º</a></li>
                  <li><a href="#" class="dropdown-item" @click.prevent="rotate(-90)" role="button">Rotar -90º</a></li>
                  <li><a ref="flipX" href="#" class="dropdown-item" @click.prevent="flipX" role="button">Voltear Horizontal</a></li>
                  <li><a ref="flipY" href="#" class="dropdown-item" @click.prevent="flipY" role="button">Voltear Vertical</a></li>                  
                  <li class="dropdown-divider"></li>
                  <li><a href="#" class="dropdown-item" @click.prevent="reset" role="button">Resetear valores</a></li>
                </ul>
              </div>
            </div>
          </section>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-primary" @click.prevent="close">Cancelar</button>
          <button type="button" class="btn btn-primary" @click.prevent="cropImage">Cortar</button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';

export default {
  name: 'CropperImg',
  components: {
    VueCropper
  },
  props: {
    showCropper: Boolean,
    loadedImg: String,
    width: Number,
    height: Number,    
    minContainerHeight: Number,
    minCanvasWidth: Number,
    ratio: Number
  },
  methods: {
    close() {
      this.$emit('close');
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      let cropImg = this.$refs.cropper.getCroppedCanvas({ width: this.width, height: this.height }).toDataURL();
      this.$emit('cropImage', cropImg);
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute('data-scale', scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute('data-scale');
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute('data-scale', scale);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf('image/') === -1) {
        alert('Please select an image file');
        return;
      }
      if (typeof FileReader === 'function') {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated sourceF
          this.$refs.cropper.replace(event.target.result);
        };
        reader.readAsDataURL(file);
      } else {
        alert('Sorry, FileReader API not supported');
      }
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  watch: {
    loadedImg: function () {
      this.$refs.cropper.replace(this.loadedImg);
    },
    ratio: function () {
      this.$refs.cropper.setAspectRatio(this.ratio)
    }
  }
}
</script>
  
  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
input[type="file"] {
  display: none;
}

.content {
  display: flex;
  justify-content: space-between;
  margin: auto;
}

.cropper-area {
  max-width: 500px;
}

.actions {
  margin-top: 1rem;
}

.btn-flat {
  display: flex;
  align-items: center;
  gap: 0.45rem;
  color: #212529;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0 !important;
}

.btn-flat:hover {
  color: var(--main-primary-color) !important;
}

.btn-flat i {
  font-size: 1.05rem;
}

textarea {
  width: 100%;
  height: 100px;
}

.preview-area {
  width: 307px;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 1rem;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (9 / 16));
  overflow: hidden;
}
</style>
  