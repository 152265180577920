<template>
<router-view></router-view>

</template>

<script>
import '@/assets/css/tours-custom.css'

export default {
  name: 'App',
  mounted(){
    var search = window.location.search;
    var userId = search.split('?c=')[1];
    var domain = window.location.host;
    domain = domain.replace('cms.' ,'');
    // userId = 'OTExeW9kYV9jYW1iaWFfbG9zX2NvZGlnb3M=';
    // domain = 'padawan.com.es';
    if (userId)
      this.$router.push({name:'Home',state:{dominio:domain, user_hash:userId}})
    else
      window.location.href= 'https://clientes.solucionesamedida.com/';
    
  }
}
</script>
