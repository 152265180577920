<template>
    <div class="dropdown wrap-langs lang-cms">
        <a href = "javascript:void(0);" class="d-flex align-items-center gap-2 nav-link ptrans" data-bs-toggle="dropdown" aria-expanded="false">
            <span id="text-curlang" class="text-lang" translate="no">{{ curLang }}</span>
            <i class="fa fa-globe"></i>
        </a>
        <div id="sel-lang" class="dropdown-menu dropdown-menu-end" role="menu">
            <a href="javascript:void(0);" :id="`sel-lang-${ lang.code }`" class="dropdown-item" :class="lang.code == curLang ? 'active':''" :data-gtrans="lang.code" role="menuitem" v-for="(lang, index) in mtzLang" :key="index">{{ lang.name }}</a>
        </div>
    </div>
</template>
<script setup>
import { ref } from 'vue';
const curLang = ref('es');
const mtzLang = [
    { "name": "Español", "code": "es" }, 
    { "name": "Catalán", "code": "ca" }, 
    { "name": "Gallego", "code": "gl" }, 
    { "name": "Euskera", "code": "eu" }, 
    { "name": "Inglés", "code": "en" }, 
    { "name": "Alemán", "code": "de" }, 
    { "name": "Danés", "code": "da" }, 
    { "name": "Sueco", "code": "sv" }, 
    { "name": "Francés", "code": "fr" }, 
    { "name": "Italiano", "code": "it" }, 
    { "name": "Portugués", "code": "pt" }, 
    { "name": "Ruso", "code": "ru" }, 
    { "name": "Rumano", "code": "ro" }, 
    { "name": "Neerlandés", "code": "nl" }, 
    { "name": "Griego", "code": "el" },
    { "name": "Turko", "code": "tr" }
];
</script>

<style>
.dropdown-menu {
    border: none !important;
    min-width: 180px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0.4rem !important;
}
.lang-cms .nav-link:hover {
    color: var(--main-primary-color)!important;
}
.dropdown-item {
    padding: 0.25rem 1rem !important;
    margin: 2px 0;
    border-radius: 6px;
}
.dropdown-item.active, .dropdown-item:active, .dropdown-item.active:hover, .dropdown-item.active:focus {
    color: #fff !important;
    background-color: var(--main-primary-color) !important;
    cursor: default;
}
</style>