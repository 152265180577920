<template>
    <div class="NotFound">
        <div class="main-header">
    <div class="container">
    <div class="item">
    <a href="#" class="main-logo">
    <img src="./assets/img/logo.svg" alt="YODA">
    </a>
    </div>
    </div>
        </div>
        <div class="main-wrap">
            <div class="container">
        <div class="item">
        <div class="block-left">
        <div class="box box1 wow fadeInDown animated" style="visibility: visible;">
        <h2 class="title">We generate more than <span>10,000,000 clicks</span> per day:</h2>
        <p class="description">15 years innovating and generating value for our clients. With a presence in Spain, Italy, Mexico, Colombia and the United States.</p>
        </div>
        <div class="box box2 wow fadeInRight animated" style="visibility: visible;">
        <h2 class="title">More than <span>10,000 clients</span><br> trust us:</h2>
        <p class="description">Managing your Digital Presence: Google My Business Listing, Google Ads and Facebook Ads campaigns. Pages optimized to attract Potential Clients. ChatBot and WhatsApp. Traceability of all contacts, etc.</p>
        </div>
        <div class="box box3 wow fadeInLeft animated" style="visibility: visible;">
        <h2 class="title">Betting on the <br> best <span>Partners</span>:</h2>
        <p class="description">Premier Partner of Google and Facebook. Certified Professionals and access to the latest technologies.</p>
        </div>
        </div>
        <div class="block-right wow fadeInUp animated" style="visibility: visible;">
        <div class="wrap-image" data-speed="0.05" style="transform: translate3d(-1.4328px, -4.24662px, 0px);">
        <img src="./assets/img/img-dog.png" alt="dog">
        </div>
        </div>
        </div>
        </div>
        </div>
    </div>


</template>

<script>
export default {
    name : "NotFound"
}
</script>
<style>
body{
    background-color: #affaff;
}
</style>
<style scoped>
    .NotFound{
        margin:auto;
        width: 100%;
    }
</style>
<style scoped src="@/assets/css/style_error_page.css">
</style>
